import { createAsyncThunk } from '@reduxjs/toolkit';

import axios, { endpoints as END_POINTS } from 'src/utils/axios';

export const createJob = createAsyncThunk(
  '/createJob',
  // eslint-disable-next-line consistent-return
  async (data: any, thunkAPI) => {
    try {
      // make API call to get customer data
      const response = await axios.post(END_POINTS.job.create, data);

      return response.data.job;
    } catch (e: any) {
      console.log('jobs actions - error: ', e);
      throw e;
    }
  }
);

export const editJob = createAsyncThunk('/editJob', async (data: any, thunkAPI) => {
  try {
    console.log('[JOB ACTIONS] - editJob data : ', data);
    // Make an API call to update the job
    await axios.put(END_POINTS.job.edit(data._id), data); // Replace with your edit endpoint

    return data;
  } catch (e: any) {
    console.log('jobs actions - error: ', e);
    // You can handle errors or dispatch error actions here
    throw e; // Rethrow the error to be caught by Redux Toolkit
  }
});

export const getJobList = createAsyncThunk(
  '/getJobList',
  // eslint-disable-next-line consistent-return
  async (_: void, thunkAPI) => {
    try {
      // make API call to get customer data
      const response = await axios.get(END_POINTS.job.list);

      return response.data.data;
    } catch (e: any) {
      console.log('jobs actions - error: ', e);
    }
  }
);

export const getJobListForCandidate = createAsyncThunk(
  '/getJobListForCandidate',
  // eslint-disable-next-line consistent-return
  async (_: void, thunkAPI) => {
    try {
      // make API call to get customer data
      const response = await axios.get(END_POINTS.job.listForCandidate);

      return response.data.data;
    } catch (e: any) {
      console.log('jobs actions - getJobListForCandidate - error: ', e);
      throw e;
    }
  }
);

export const publishJob = createAsyncThunk(
  '/publishJob',
  // eslint-disable-next-line consistent-return
  async (jobId: string, thunkAPI) => {
    try {
      const response = await axios.post(END_POINTS.job.publish(jobId));

      return jobId;
    } catch (e: any) {
      console.log('jobs actions - error: ', e);
    }
  }
);

export const getJobDetailsWithCandidatesData = createAsyncThunk(
  '/getJobDetailsWithCandidatesData',
  // eslint-disable-next-line consistent-return
  async (jobId: string, thunkAPI) => {
    try {
      const response = await axios.get(END_POINTS.job.getJobDetailsWithCandidates(jobId));

      return { candidateList: response.data.data, jobId };
    } catch (e: any) {
      console.log('jobs actions - error: ', e);
    }
  }
);

export const getCandidateList = createAsyncThunk(
  '/getCandidateList',
  // eslint-disable-next-line consistent-return
  async (jobId: string, thunkAPI) => {
    try {
      const response = await axios.get(END_POINTS.job.getCandidateList(jobId));

      return { candidateList: response.data.data, jobId };
    } catch (e: any) {
      console.log('jobs actions - error: ', e);
    }
  }
);

export const getJobData = createAsyncThunk(
  '/getJobData',
  // eslint-disable-next-line consistent-return
  async (jobId: string, thunkAPI) => {
    try {
      const response = await axios.get(END_POINTS.job.getJobData(jobId));

      return response.data.data;
    } catch (e: any) {
      console.log('jobs actions - error: ', e);
    }
  }
);

export const inviteCandidate = createAsyncThunk(
  '/inviteCandidate',
  // eslint-disable-next-line consistent-return
  async (data: any, thunkAPI) => {
    try {
      const response = await axios.post(
        END_POINTS.job.inviteCandidate(data.jobId),
        data.candidateData
      );

      return {
        jobId: data.jobId,
        candidateData: data.candidateData,
        invitationId: response.data.data,
      };
    } catch (e: any) {
      console.log('jobs actions - error: ', e);
      throw e;
    }
  }
);

export const removeCandidateFromJob = createAsyncThunk(
  '/removeCandidate',
  // eslint-disable-next-line consistent-return
  async (jobCandidateId: string, thunkAPI) => {
    try {
      await axios.post(END_POINTS.job.removeCandidate(jobCandidateId));

      return jobCandidateId;
    } catch (e: any) {
      console.log('jobs actions - error: ', e);
      throw e;
    }
  }
);

export const letAiDecide = createAsyncThunk(
  '/letAiDecide',
  // eslint-disable-next-line consistent-return
  async (jobId: string, thunkAPI) => {
    try {
      const response = await axios.get(END_POINTS.job.letAiDecide(jobId));

      return response.data.data;
    } catch (e: any) {
      console.log('jobs actions - error: ', e);
    }
  }
);
