// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
// hooks

// routes

// locales
// components
import Label from 'src/components/label';

import { useAuthContext } from 'src/auth/hooks';

// ----------------------------------------------------------------------

export default function NavUpgrade() {
  const { user } = useAuthContext();

  if (!user) {
    return null;
  }

  const { company } = user;

  const upgradeToProHandler = () => {
    const mailTo = 'sales@colloAI.com';
    const messageSubject = 'Request to Upgrade to Pro – [Your Company Name]';
    const messageBody = `
Hello Collo AI Team,

I’m interested in upgrading to the Pro version of your service. Could you please provide information about the process?

Thank you very much for your help!

`;
    window.location.href = `mailto:${mailTo}?subject=${messageSubject}&body=${messageBody}`;
  };

  return (
    <Stack
      sx={{
        px: 2,
        py: 5,
        textAlign: 'center',
      }}
    >
      <Stack alignItems="center">
        <Box sx={{ position: 'relative' }}>
          {/* <Avatar src={mockUser.user?.photoURL} alt={mockUser.user?.displayName} sx={{ width: 48, height: 48 }} />

          display the initial instead of the photoURL
          */}

          <Avatar
            alt={`${user?.firstName} ${user?.lastName} `}
            sx={{ width: 48, height: 48, fontWeight: 'bold' }}
            color="primary"
          >
            {user && user.firstName
              ? user.firstName[0].toUpperCase()
              : user?.email[0].toUpperCase()}
          </Avatar>
          <Label
            color="success"
            variant="filled"
            sx={{
              top: -6,
              px: 0.5,
              left: 40,
              height: 20,
              position: 'absolute',
              borderBottomLeftRadius: 2,
            }}
          >
            {company && company.isSubscriptionActive ? 'Premium' : 'Free'}
          </Label>
        </Box>

        <Stack spacing={0.5} sx={{ mt: 1.5, mb: 2 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.firstName} {user?.lastName}
          </Typography>

          <Typography variant="body2" noWrap sx={{ color: 'text.disabled' }}>
            {user?.email}
          </Typography>
        </Stack>

        {(!company || company.isSubscriptionActive !== true) && (
          <Button variant="contained" onClick={upgradeToProHandler}>
            Upgrade to Pro
          </Button>
        )}
      </Stack>
    </Stack>
  );
}
