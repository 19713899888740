import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const IndexPage = lazy(() => import('src/pages/dashboard'));

// JOB
const JobDetailsPage = lazy(() => import('src/pages/dashboard/employer/job/details'));
const JobListPage = lazy(() => import('src/pages/dashboard/employer/job/list'));
const JobCreatePage = lazy(() => import('src/pages/dashboard/employer/job/new'));
const JobEditPage = lazy(() => import('src/pages/dashboard/employer/job/edit'));

// Interview
const JobInterviewPage = lazy(() => import('src/pages/dashboard/employer/interview/job-interview'));

// Candidate
const CandidatePage = lazy(() => import('src/pages/dashboard/candidate/index'));
const CandidateJobDetailsPage = lazy(
  () => import('src/pages/dashboard/candidate/candidate-job-details')
);

// USER
const UserAccountPage = lazy(() => import('src/pages/dashboard/user/account'));

// COMPANY
const CompanyOverviewPage = lazy(() => import('src/pages/dashboard/company/overview'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },

      {
        path: 'user',
        children: [
          { element: <UserAccountPage />, index: true },
          // { path: 'profile', element: <UserProfilePage /> },
          // { path: 'cards', element: <UserCardsPage /> },
          // { path: 'list', element: <UserListPage /> },
          // { path: 'new', element: <UserCreatePage /> },
          // { path: ':id/edit', element: <UserEditPage /> },
          { path: 'account', element: <UserAccountPage /> },
        ],
      },
      {
        path: 'company',
        children: [
          { element: <CompanyOverviewPage />, index: true },          
        ],
      },
      
      {
        path: 'job',
        children: [
          { element: <JobListPage />, index: true },
          { path: 'new', element: <JobCreatePage /> },
          { path: 'list', element: <JobListPage /> },

          { path: ':id/edit', element: <JobEditPage /> },
          { path: ':id', element: <JobDetailsPage /> },
        ],
      },
      {
        path: 'interview',
        children: [
          // { element: <CandidatePage />, index: true },
          { path: ':id', element: <JobInterviewPage /> },
        ],
      },
      {
        path: 'candidate',
        children: [
          { element: <CandidatePage />, index: true },
          { path: 'job-details/:id', element: <CandidateJobDetailsPage /> },
        ],
      },
    ],
  },
];
