import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { IJobCandidate, IJobItem } from 'src/types/job';

import { sub } from 'date-fns';
import type { RootState } from '../store';

import {
  candidateLanding,
  candidateGetJobData,
  approveInterview,
  rejectInterview,
  initiateInterview,
  sendInterviewMessage,
  finishInterview,
  applyPublicJob,
  sendVoiceMessage,
  submitQuestionAnswers,
} from '../actions/candidate.actions';

interface ICandidate {
  email: string;
  candidateId: string;
  participationToken: string;
}

// Define de type for the initial state
interface ICandidateState {
  status: 'idle' | 'loading' | 'failed' | 'success';
  error: any;
  candidate: ICandidate | undefined;
  currentJobId?: string;
  currentJobData?: any;
  audioUrl?: string;
  message: string | null;
}

// Define the initial state using that type
const initialState: ICandidateState = {
  status: 'idle',
  error: null,
  candidate: undefined,
  message: null,
};

export const candidateSlice = createSlice({
  name: 'candidate',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
    clearSuccessMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(candidateLanding.pending, (state: ICandidateState) => {
      state.status = 'loading';
    });
    builder.addCase(
      candidateLanding.fulfilled,
      (state: ICandidateState, action: PayloadAction<any>) => {
        if (action.payload) {
          state.candidate = {
            email: action.payload.email,
            candidateId: action.payload.candidateId,
            participationToken: action.payload.participationToken,
          };
          state.currentJobId = action.payload.jobId;
          state.status = 'success';
        }
      }
    );
    builder.addCase(candidateGetJobData.pending, (state: any) => {
      state.status = 'loading';
      state.selectedJob = undefined;
    });
    builder.addCase(candidateGetJobData.fulfilled, (state: any, action: PayloadAction<any>) => {
      if (action.payload) {
        state.status = 'success';
        state.currentJobData = action.payload;
      }
    });
    builder.addCase(approveInterview.pending, (state: any) => {
      state.status = 'loading';
    });
    builder.addCase(approveInterview.fulfilled, (state: any, action: PayloadAction<any>) => {
      if (action.payload) {
        state.status = 'success';
        state.currentJobData.interview.isApproved = true;
        state.currentJobData.interview.rejectionReason = undefined;
      }
    });
    builder.addCase(rejectInterview.pending, (state: any) => {
      state.status = 'loading';
    });
    builder.addCase(rejectInterview.fulfilled, (state: any, action: PayloadAction<any>) => {
      if (action.payload) {
        const { approvalData } = action.payload;
        state.status = 'success';
        state.currentJobData.interview.isApproved = false;
        state.currentJobData.interview.rejectionReason = {
          ...state.currentJobData.interview.rejectReason,
          predefined: approvalData.rejectReason,
          comment: approvalData.comment,
        };
      }
    });
    builder.addCase(initiateInterview.pending, (state: any) => {
      state.status = 'loading';
    });
    builder.addCase(initiateInterview.fulfilled, (state: any, action: PayloadAction<any>) => {
      if (action.payload) {
        const { candidateJobData, aiResponse } = action.payload;
        if (candidateJobData) {
          const { interview } = candidateJobData;
          state.currentJobData.interview = interview;
          state.status = 'success';
          // Store audioUrl if available
        }

        if (aiResponse) {
          const { audioUrl } = aiResponse;
          state.audioUrl = audioUrl;
        }
      }
    });
    builder.addCase(sendInterviewMessage.pending, (state: any, action: any) => {
      const messageObj = {
        role: 'user',
        messageDate: new Date().toISOString(),
        content: action.meta.arg.message,
        _id: new Date().toISOString(),
      };
      state.status = 'loading';

      state.currentJobData.interview.conversation = [
        ...state.currentJobData.interview.conversation,
        messageObj,
      ];
    });
    builder.addCase(sendInterviewMessage.fulfilled, (state: any, action: PayloadAction<any>) => {
      if (action.payload) {
        const { message, aiResponse } = action.payload;

        // const messageObj = {
        //   sender: 'Candidate',
        //   messageDate: new Date().toISOString(),
        //   content: message,
        //   _id: '?',
        // };

        const aiResponseObj = {
          role: 'assistant',
          messageDate: new Date().toISOString(),
          content: aiResponse.message,
          _id: new Date().toISOString(),
        };

        state.status = 'success';
        state.currentJobData.interview.conversation = [
          ...state.currentJobData.interview.conversation,
          // messageObj,
          aiResponseObj,
        ];
        // check if the interview is finished
        if (aiResponse.isInterviewFinished) {
          state.currentJobData.interview.interviewFinishedTime = new Date().toISOString();
          state.currentJobData.interview.finishType = 'AI';
        }

        // Store audioUrl if available
        if (aiResponse.audioUrl) {
          state.audioUrl = aiResponse.audioUrl;
        }
      }
    });
    // Handle sendVoiceMessage
    builder.addCase(sendVoiceMessage.pending, (state: any) => {
      state.status = 'loading';
    });
    builder.addCase(sendVoiceMessage.fulfilled, (state: any, action: PayloadAction<any>) => {
      if (action.payload) {
        const { audioUrl, aiMessage, isInterviewFinished, candidateMessage } = action.payload;

        // add candidate's message to conversation
        // #region add candidate's message to conversation
        const messageObj = {
          role: 'user',
          messageDate: new Date().toISOString(),
          content: candidateMessage,
          _id: new Date().toISOString(),
        };

        state.currentJobData.interview.conversation = [
          ...state.currentJobData.interview.conversation,
          messageObj,
        ];
        // #endregion

        const aiResponseObj = {
          role: 'assistant',
          messageDate: new Date().toISOString(),
          content: aiMessage,
          _id: `${new Date().getTime()}-${Math.floor(Math.random() * 10000)}`,
        };

        state.status = 'success';
        state.currentJobData.interview.conversation = [
          ...state.currentJobData.interview.conversation,
          aiResponseObj,
        ];

        if (isInterviewFinished) {
          state.currentJobData.interview.interviewFinishedTime = new Date().toISOString();
          state.currentJobData.interview.finishType = 'AI';
        }

        if (audioUrl) {
          state.audioUrl = audioUrl;
        }
      }
    });
    builder.addCase(finishInterview.pending, (state: any) => {
      state.status = 'loading';
    });
    builder.addCase(finishInterview.fulfilled, (state: any, action: PayloadAction<any>) => {
      if (action.payload) {
        const { candidateJobData } = action.payload;
        const { interview } = candidateJobData;
        state.currentJobData.interview = interview;

        state.status = 'success';
      }
    });
    builder.addCase(applyPublicJob.pending, (state: any) => {
      state.status = 'loading';
    });
    builder.addCase(applyPublicJob.fulfilled, (state: any, action: PayloadAction<any>) => {
      if (action.payload) {
        state.status = 'success';
      }
    });
    builder.addCase(submitQuestionAnswers.pending, (state: any) => {
      state.status = 'loading';
    });
    builder.addCase(submitQuestionAnswers.fulfilled, (state: any, action: PayloadAction<any>) => {
      if (action.payload) {
        state.status = 'success';
        state.message = 'Answers submitted successfully';
        state.currentJobData.customQuestions = {
          submittedAt: new Date().toISOString(),
          answers: action.payload,
        };
      }
    });
    builder.addCase(submitQuestionAnswers.rejected, (state: any, action: any) => {
      state.status = 'failed';
      state.error =
        action.error && action.error.message ? action.error.message : 'Error submitting answers';
    });
  },
});

export const { actions } = candidateSlice;

export const { clearError, clearSuccessMessage } = actions;

// Other code such as selectors can use the imported `RootState` type
export const selectCount = (state: RootState) => state.counter.value;

export default candidateSlice.reducer;
