import { createAsyncThunk } from '@reduxjs/toolkit';

import axios, { endpoints as END_POINTS } from 'src/utils/axios';

import { IUserAccountChangePassword } from 'src/types/user';

export const confirmAccount = createAsyncThunk(
  '/confirmAccount',
  // eslint-disable-next-line consistent-return
  async (token: string, thunkAPI) => {
    try {
      // make API call to get customer data
      const response = await axios.get(END_POINTS.auth.confirmAccount(token));

      return response.data;
    } catch (e: any) {
      console.log('jobs actions - error: ', e);
      console.log('jobs actions - error.message: ', e.message);
      throw e;
    }
  }
);

export const updateUserInfo = createAsyncThunk(
  '/updateUserInfo',
  // eslint-disable-next-line consistent-return
  async (userUpdateData: any, thunkAPI) => {
    try {
      // make API call to get customer data
      const response = await axios.put(END_POINTS.user.updateAccount, userUpdateData);

      return response.data;
    } catch (e: any) {
      console.log('jobs actions - error: ', e);
      console.log('jobs actions - error.message: ', e.message);
      throw e;
    }
  }
);

export const updatePassword = createAsyncThunk(
  '/updatePassword',
  // eslint-disable-next-line consistent-return
  async (updateData: IUserAccountChangePassword, thunkAPI) => {
    try {
      // make API call to get customer data
      const response = await axios.put(END_POINTS.user.updatePassword, updateData);

      return response.data;
    } catch (e: any) {
      console.log('jobs actions - error: ', e);
      console.log('jobs actions - error.message: ', e.message);
      throw e;
    }
  }
);

export const sendConfirmationLink = createAsyncThunk(
  '/sendConfirmationLink',
  // eslint-disable-next-line consistent-return
  async (_, thunkAPI) => {
    try {
      // make API call to get customer data
      const response = await axios.get(END_POINTS.user.sendConfirmationLink);

      return response.data;
    } catch (e: any) {
      console.log('jobs actions - error: ', e);
      console.log('jobs actions - error.message: ', e.message);
      throw e;
    }
  }
);

export const waitingListSuccess = createAsyncThunk(
  '/waitingListSuccess',
  // eslint-disable-next-line consistent-return
  async (_, thunkAPI) => {
    try {
      // return success message
      return true;
    } catch (e: any) {
      console.log('waitingListSuccess - error: ', e);
      console.log('waitingListSuccess - error.message: ', e.message);
      throw e;
    }
  }
);
