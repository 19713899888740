import { useCallback, useEffect } from 'react';
// routes
import { paths } from 'src/routes/paths';
import { useRouter, useSearchParams, useParams, usePathname } from 'src/routes/hooks';
//
import { PATHS_WITHOUT_AUTH } from 'src/utils/config';
import { useAuthContext } from '../hooks';


// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
  noRedirect?: boolean;
};

export default function GuestGuard({ children, noRedirect }: Props) {
  const router = useRouter();

  const searchParams = useSearchParams();

  const pathname = usePathname();
  // destruct the pathname and get the last part of the path
  const lastPath = pathname.split('/').pop();
  // check if the path in the PATHS_WITHOUT_AUTH array
  const isNoAuthPath = PATHS_WITHOUT_AUTH.includes(lastPath as string);

  const { authenticated, jobInterviewId } = useAuthContext();

  const returnTo = !jobInterviewId
    ? searchParams.get('returnTo') || paths.dashboard.root
    : `${paths.dashboard.root}/candidate/job-details/${jobInterviewId}`;

  const check = useCallback(() => {
    if (authenticated && !isNoAuthPath) {
      router.replace(returnTo);
    }
  }, [authenticated, returnTo, router, isNoAuthPath]);

  useEffect(() => {
    check();
  }, [check]);

  return <>{children}</>;
}
