import { useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
import axios, { endpoints } from 'src/utils/axios';
import { HOST_API } from 'src/config-global';
import { setEmail } from 'src/store/reducers/userSlice'; // Replace with the actual path to your userSlice.
import { useAppDispatch } from 'src/store/hooks';
import { usePathname } from 'src/routes/hooks';
import { PATHS_WITHOUT_AUTH } from 'src/utils/config';

import { waitingListSuccess } from 'src/store/actions/user.actions';
//
import { AuthContext } from './auth-context';
import { isValidToken, setSession, jwtDecode } from './utils';
import { ActionMapType, AuthStateType, AuthUserType } from '../../types';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
  UPDATE_USER_INFO = 'UPDATE_USER_INFO',
  WAITING_LIST = 'WAITING_LIST',
}

type Payload = {
  [Types.INITIAL]: {
    user: AuthUserType;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
    jobInterviewId?: string;
  };
  [Types.REGISTER]: {
    user: AuthUserType;
  };
  [Types.LOGOUT]: undefined;
  [Types.UPDATE_USER_INFO]: {};
  [Types.WAITING_LIST]: {};
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  user: null,
  loading: true,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      user: action.payload.user,
      jobInterviewId: action.payload.jobInterviewId,
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      user: null,
    };
  }
  if (action.type === Types.UPDATE_USER_INFO) {
    return {
      ...state,
      user: {
        ...state.user,
        ...action.payload,
      },
    };
  }
  if (action.type === Types.WAITING_LIST) {
    return {
      ...state,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const dispatchLocalState = useAppDispatch();

  const pathname = usePathname();
  // destruct the pathname and get the last part of the path
  const lastPath = pathname.split('/').pop();
  // check if the path in the PATHS_WITHOUT_AUTH array
  const isNoAuthPath = PATHS_WITHOUT_AUTH.includes(lastPath as string);

  const initialize = useCallback(async () => {
    try {
      const accessToken = sessionStorage.getItem(STORAGE_KEY);
      if (accessToken && isValidToken(accessToken) && !isNoAuthPath) {
        setSession(accessToken);

        const response = await axios.post(endpoints.auth.me);

        const { user } = response.data;

        dispatch({
          type: Types.INITIAL,
          payload: {
            user,
          },
        });
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: Types.INITIAL,
        payload: {
          user: null,
        },
      });
    }
  }, [isNoAuthPath]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email: string, password: string) => {
    const data = {
      email,
      password,
    };

    try {
      const response = await axios.post(endpoints.auth.login, data);

      const { accessToken, user } = response.data;

      setSession(accessToken);

      dispatch({
        type: Types.LOGIN,
        payload: {
          user,
        },
      });
    } catch (error) {
      console.error(error);
      throw new Error(error.message);
    }
  }, []);

  // REGISTER
  const register = useCallback(
    async (
      email: string,
      password: string,
      firstName: string,
      lastName: string,
      companyName: string,
      referralCode?: string
    ) => {
      const data = {
        email: email.toLowerCase(),
        password,
        firstName,
        lastName,
        companyName,
        role: 'employer',
        referralCode
      };

      const response = await axios.post(endpoints.auth.register, data);

      const { accessToken, user } = response.data.data;

      sessionStorage.setItem(STORAGE_KEY, accessToken);

      dispatch({
        type: Types.REGISTER,
        payload: {
          user,
        },
      });
    },
    []
  );

  // UPDATE USER INFO
  const updateUserInfo = useCallback(async (firstName: string, lastName: string) => {
    const data = {
      firstName,
      lastName,
    };

    await axios.put(endpoints.user.updateAccount, data);

    dispatch({
      type: Types.UPDATE_USER_INFO,
      payload: data,
    });
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    dispatch({
      type: Types.LOGOUT,
    });
  }, []);

  const candidateLanding = useCallback(
    async (token: string) => {
      try {
        const response = await axios.get(endpoints.candidate.landing(token));

        const { participationToken, email, isEmailVerified, firstName, lastName, userId } =
          response.data;

        const user = {
          _id: userId,
          email,
          role: 'candidate',
          isEmailVerified,
          firstName,
          lastName,
        };

        const decodedToken = jwtDecode(participationToken);

        const { jobId } = decodedToken;

        setSession(participationToken);
        dispatchLocalState(setEmail(email));

        dispatch({
          type: Types.LOGIN,
          payload: {
            user,
            jobInterviewId: jobId,
          },
        });
      } catch (error) {
        console.error(error);
        throw new Error(error.message);
      }
    },
    [dispatchLocalState]
  );

  const applyPublicJob = useCallback(
    async (data: any) => {
      try {
        const response = await axios.post(endpoints.candidate.applyPublicJob(data.jobId), data);

        const { token } = response.data;

        const user = { _id: '', email: data.email, role: 'candidate' };

        setSession(token);
        dispatchLocalState(setEmail(data.email));

        dispatch({
          type: Types.LOGIN,
          payload: {
            user,
            jobInterviewId: data.jobId,
          },
        });
      } catch (error) {
        console.error(error);
        throw new Error(error.message);
      }
    },
    [dispatchLocalState]
  );

  // WAITING LIST REGISTER
  const waitingList = useCallback(
    async (
      email: string,
      firstName: string,
      lastName: string,
      companyName: string,
      jobTitle?: string,
      industry?: string,
      companySize?: string,
      howDidYouHearAboutUs?: string,
      commentsOrQuestions?: string
    ) => {
      const data = {
        email: email.toLowerCase(),
        firstName,
        lastName,
        companyName,
        jobTitle,
        industry,
        companySize,
        howDidYouHearAboutUs,
        commentsOrQuestions,
      };

      await axios.post(endpoints.auth.waitingList, data);

      dispatchLocalState(waitingListSuccess());

      dispatch({
        type: Types.WAITING_LIST,
        payload: {},
      });
    },
    [dispatchLocalState]
  );

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const jobId = state.jobInterviewId;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      jobInterviewId: jobId,
      //
      login,
      register,
      logout,
      candidateLanding,
      applyPublicJob,
      updateUserInfo,
      waitingList,
    }),
    [
      login,
      logout,
      register,
      state.user,
      status,
      candidateLanding,
      jobId,
      applyPublicJob,
      updateUserInfo,
      waitingList,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
