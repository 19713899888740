
export const PATHS_WITHOUT_AUTH: string[] = ['confirm-account'];

export const OTHER_CONFIGS: any[] = [];

export interface JobStatus {
  label: string;
  color: 'default' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';
  buttonColor?: 'default' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';
}

export interface IJobStatusConfig {
  [key: string]: JobStatus;
}

// Note the use of "as const" to infer string literal types for keys
export const JOB_STATUS_CONFIG: IJobStatusConfig = {
  draft: {
    label: 'Draft',
    color: 'default',
    buttonColor: 'info',
  },
  active: {
    label: 'Active',
    color: 'success',
    buttonColor: 'success',
  },
  expired: {
    label: 'Expired',
    color: 'warning',
    buttonColor: 'warning',
  },
} as const;