import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { IJobCandidate, IJobItem } from 'src/types/job';

import type { RootState } from '../store';

import {
  getJobList,
  createJob,
  publishJob,
  getJobDetailsWithCandidatesData,
  getCandidateList,
  getJobData,
  inviteCandidate,
  removeCandidateFromJob,
  editJob,
  getJobListForCandidate,
  letAiDecide,
} from '../actions/jobs.actions';

// Define an exportable state type interface for job status
export type JobStatusType = 'idle' | 'loading' | 'success' | 'failed';

// Define de type for the initial state
interface IJobsState {
  status: JobStatusType;
  error: any;
  jobList: IJobItem[];
  selectedJob: IJobItem | undefined;
  message: string | null;
}

// Define the initial state using that type
const initialState: IJobsState = {
  status: 'idle',
  error: null,
  jobList: [],
  selectedJob: undefined,
  message: null,
};

export const jobsSlice = createSlice({
  name: 'jobs',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
    clearSuccessMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(getJobList.pending, (state: IJobsState) => {
      state.status = 'loading';
    });
    builder.addCase(
      getJobList.fulfilled,
      (state: IJobsState, action: PayloadAction<Array<IJobItem>>) => {
        if (action.payload) {
          state.jobList = action.payload;
          state.status = 'success';
        }
      }
    );
    builder.addCase(createJob.pending, (state: IJobsState) => {
      state.status = 'loading';
    });
    builder.addCase(
      createJob.fulfilled,
      (state: IJobsState, action: PayloadAction<Array<IJobItem>>) => {
        if (action.payload) {
          state.status = 'success';
          state.jobList = state.jobList.concat(action.payload);
          state.message = 'Job created successfully';
        }
      }
    );
    builder.addCase(createJob.rejected, (state: IJobsState, action: PayloadAction<any>) => {
      state.status = 'failed';
      state.error = `Error creating job`;
    });
    // Add a case for editing a job
    builder.addCase(editJob.pending, (state: IJobsState) => {
      state.status = 'loading';
    });
    builder.addCase(editJob.fulfilled, (state: IJobsState, action: PayloadAction<IJobItem>) => {
      state.status = 'success';
      state.message = 'Job updated successfully';

      // Update the job in the jobList array with the edited data
      const editedJob = action.payload;

      // const index = state.jobList.findIndex((job) => job._id === editedJob._id);
      // if (index !== -1) {
      //   state.jobList[index] = editedJob;
      // }
    });
    builder.addCase(editJob.rejected, (state: IJobsState, action: PayloadAction<IJobItem>) => {
      state.status = 'failed';
      state.error = 'Error updating job';
    });
    builder.addCase(publishJob.pending, (state: IJobsState) => {
      state.status = 'loading';
    });
    builder.addCase(publishJob.fulfilled, (state: IJobsState, action: PayloadAction<string>) => {
      // loop through the jobList and update the job's publishDate value
      if (action.payload) {
        state.status = 'success';
        if (state.selectedJob) {
          state.selectedJob.publishDate = new Date();
        }
      }
    });
    builder.addCase(getJobDetailsWithCandidatesData.pending, (state: any) => {
      state.status = 'loading';
    });
    builder.addCase(
      getJobDetailsWithCandidatesData.fulfilled,
      (state: IJobsState, action: PayloadAction<any>) => {
        if (action.payload) {
          state.status = 'success';
          state.jobList = state.jobList.map((job: IJobItem) => {
            if (job._id === action.payload.jobId) {
              return {
                ...job,
                candidates: action.payload.candidateList,
              };
            }
            return job;
          });
        }
      }
    );
    builder.addCase(getCandidateList.pending, (state: any) => {
      state.status = 'loading';
    });
    builder.addCase(getCandidateList.fulfilled, (state: IJobsState, action: PayloadAction<any>) => {
      if (action.payload) {
        state.status = 'success';
        state.jobList = state.jobList.map((job: IJobItem) => {
          if (job._id === action.payload.jobId) {
            return {
              ...job,
              candidates: action.payload.candidateList,
            };
          }
          return job;
        });
      }
    });
    builder.addCase(getJobData.pending, (state: any) => {
      state.status = 'loading';
      state.selectedJob = undefined;
    });
    builder.addCase(getJobData.fulfilled, (state: IJobsState, action: PayloadAction<any>) => {
      if (action.payload) {
        state.status = 'success';
        state.selectedJob = action.payload;
      }
    });
    builder.addCase(removeCandidateFromJob.pending, (state: any) => {
      state.status = 'loading';
    });
    builder.addCase(
      removeCandidateFromJob.fulfilled,
      (state: IJobsState, action: PayloadAction<any>) => {
        if (action.payload) {
          state.status = 'success';
          state.message = 'Candidate removed successfully';
          if (state.selectedJob) {
            state.selectedJob = {
              ...state.selectedJob,
              candidates: state.selectedJob.candidates.filter(
                (candidate: IJobCandidate) => candidate._id !== action.payload
              ),
            };
          }
        }
      }
    );
    builder.addCase(removeCandidateFromJob.rejected, (state: IJobsState, action: any) => {
      state.status = 'failed';
      state.error = 'Error removing candidate';
    });
    builder.addCase(inviteCandidate.pending, (state: any) => {
      state.status = 'loading';
    });
    builder.addCase(inviteCandidate.fulfilled, (state: IJobsState, action: PayloadAction<any>) => {
      if (action.payload) {
        const { jobId, candidateData, invitationId } = action.payload;

        const candidateInvitationData: IJobCandidate = {
          _id: invitationId,
          candidateUserId: {
            interview: {},
            email: candidateData.candidateEmail,
            firstName: candidateData.firstName,
            lastName: candidateData.lastName,
            jobId,
          },
          name: '',
          role: '',
          avatarUrl: '',
        };

        if (state.selectedJob) {
          state.selectedJob = {
            ...state.selectedJob,
            candidates: state.selectedJob.candidates.concat(candidateInvitationData),
          };
        }
        state.status = 'success';
        state.message = 'Candidate invited successfully';
      }
    });
    builder.addCase(inviteCandidate.rejected, (state: IJobsState, action: any) => {
      state.status = 'failed';
      state.error = 'Error inviting candidate';
    });

    builder.addCase(getJobListForCandidate.pending, (state: IJobsState) => {
      state.status = 'loading';
    });
    builder.addCase(
      getJobListForCandidate.fulfilled,
      (state: IJobsState, action: PayloadAction<Array<IJobItem>>) => {
        if (action.payload) {
          state.jobList = action.payload;
          state.status = 'success';
        }
      }
    );
    builder.addCase(letAiDecide.pending, (state: IJobsState) => {
      state.status = 'loading';
    });
    builder.addCase(letAiDecide.fulfilled, (state: IJobsState, action: PayloadAction<any>) => {
      state.status = 'success';
      state.message = "AI's report is ready";
      if (state.selectedJob) {
        state.selectedJob = {
          ...state.selectedJob,
          aiReports: state.selectedJob.aiReports
            ? [...state.selectedJob.aiReports, action.payload]
            : [action.payload],
        };
      }
    });
    builder.addCase(letAiDecide.rejected, (state: IJobsState, action: any) => {
      state.status = 'failed';
      state.error = 'Error letting AI decide';
    });
  },
});

export const { actions } = jobsSlice;

export const { clearError, clearSuccessMessage } = actions;

// Other code such as selectors can use the imported `RootState` type
export const selectCount = (state: RootState) => state.counter.value;

export default jobsSlice.reducer;
