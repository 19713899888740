import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import counterSliceReducer from './reducers/counterSlice';
import jobsSliceReducer from './reducers/JobsSlice';
import userSliceReducer from './reducers/userSlice';
import candidateSliceReducer from './reducers/CandidateSlice';
// ...

export const store = configureStore({
  reducer: {
    user: userSliceReducer,
    jobs: jobsSliceReducer,
    counter: counterSliceReducer,
    candidate: candidateSliceReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch; // Export a hook that can be reused to resolve types
