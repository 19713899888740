import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  confirmAccount,
  updateUserInfo,
  updatePassword,
  sendConfirmationLink,
  waitingListSuccess,
} from '../actions/user.actions';

// Define a type for the state
interface UserState {
  status: 'idle' | 'loading' | 'failed' | 'success';
  error: any;
  email: string | null;
  message: string | null;
}

// Define the initial state
const initialState: UserState = {
  status: 'idle',
  error: undefined,
  email: null,
  message: null,
};

// Create a slice
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // Action to set the user's email
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    // Action to clear the user's email
    clearEmail: (state) => {
      state.email = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearSuccessMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(confirmAccount.pending, (state: any) => {
      state.status = 'loading';
    });
    builder.addCase(confirmAccount.fulfilled, (state: any, action: PayloadAction<any>) => {
      state.status = 'success';
    });
    builder.addCase(confirmAccount.rejected, (state: any, action: any) => {
      state.status = 'failed';
      state.error = action.error.message;
    });
    builder.addCase(updateUserInfo.pending, (state: any) => {
      state.status = 'loading';
    });
    builder.addCase(updateUserInfo.fulfilled, (state: any, action: PayloadAction<any>) => {
      state.status = 'success';
    });
    builder.addCase(updateUserInfo.rejected, (state: any, action: any) => {
      state.status = 'failed';
      state.error = action.error.message;
    });
    builder.addCase(updatePassword.pending, (state: any) => {
      state.status = 'loading';
    });
    builder.addCase(updatePassword.fulfilled, (state: any) => {
      state.status = 'success';
      state.message = 'Password updated successfully';
    });
    builder.addCase(updatePassword.rejected, (state: any, action: any) => {
      state.status = 'failed';
      state.error = action.error.message;
    });
    builder.addCase(sendConfirmationLink.pending, (state: any) => {
      state.status = 'loading';
    });
    builder.addCase(sendConfirmationLink.fulfilled, (state: any) => {
      state.status = 'success';
      state.message = 'Email confirmation link sent successfully.';
    });
    builder.addCase(sendConfirmationLink.rejected, (state: any, action: any) => {
      state.status = 'failed';
      state.error = action.error.message;
    });
    builder.addCase(waitingListSuccess.fulfilled, (state: any) => {
      state.status = 'success';
      state.message = 'You have successfully joined the Waiting List!';
    });
  },
});

// Export actions
export const { setEmail, clearEmail, clearError, clearSuccessMessage } = userSlice.actions;

// Export the reducer
export default userSlice.reducer;
