// scrollbar
import 'simplebar-react/dist/simplebar.min.css';

// image
import 'react-lazy-load-image-component/src/effects/blur.css';

// mui
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

// ----------------------------------------------------------------------

// routes
import Router from 'src/routes/sections';
// theme
import ThemeProvider from 'src/theme';
// hooks
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
import useErrorHandling from 'src/hooks/custom/use-error-handling';
import useSuccessHandling from 'src/hooks/custom/use-success-handling';
// components
import ProgressBar from 'src/components/progress-bar';
import MotionLazy from 'src/components/animate/motion-lazy';
import { SettingsProvider, SettingsDrawer } from 'src/components/settings';
// auth
import { AuthProvider, AuthConsumer } from 'src/auth/context/jwt';

// ----------------------------------------------------------------------

export default function App() {
  useScrollToTop();
  const { error, handleClose } = useErrorHandling();
  const { success, handleSuccessClose } = useSuccessHandling();

  return (
    <AuthProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SettingsProvider
          defaultSettings={{
            themeMode: 'light', // 'light' | 'dark'
            themeDirection: 'ltr', //  'rtl' | 'ltr'
            themeContrast: 'default', // 'default' | 'bold'
            themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
            themeColorPresets: 'purple', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
            themeStretch: false,
          }}
        >
          <ThemeProvider>
            <MotionLazy>
              {/* <SettingsDrawer /> */}
              <ProgressBar />
              <AuthConsumer>
                <Router />
              </AuthConsumer>
            </MotionLazy>
          </ThemeProvider>
        </SettingsProvider>
      </LocalizationProvider>

      {/* Snackbar for error handling */}
      <Snackbar open={!!error} autoHideDuration={6000} onClose={handleClose}>
        <MuiAlert elevation={6} variant="filled" severity="error" onClose={handleClose}>
          {error}
        </MuiAlert>
      </Snackbar>
      {/* Snackbar for success message handling */}
      <Snackbar open={!!success} autoHideDuration={6000} onClose={handleSuccessClose}>
        <MuiAlert elevation={6} variant="filled" severity="success" onClose={handleSuccessClose}>
          {success}
        </MuiAlert>
      </Snackbar>
    </AuthProvider>
  );
}
